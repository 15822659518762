import { useUser } from "hooks/useAuth";
import UserLoading from "components/user-loading";
import { default as React, useState } from "react";
import { Button, List, Select } from "antd";
import { Message } from "../components/message";
import { NewMessage } from "../components/new-message";
import { useThreadsQuery, useUnreadMessagesQuery } from "generated/graphql";
import { ErrorGraphic } from "../components/error-graphic";
import { last } from "lodash";
import { useRouter } from "next/router";

function readFilter(filterBy) {
  switch (filterBy) {
    case "read":
      return true;
    case "unread":
      return false;
    default:
      return undefined;
  }
}

function audienceFilter(filterBy) {
  switch (filterBy) {
    case "public":
      return "PUBLIC";
    case "private":
      return "PRIVATE";
    default:
      return undefined;
  }
}

function orderFilter(sortBy) {
  switch (sortBy) {
    case "provider a-z":
      return "send_to__user__first_name";
    default:
      return undefined;
  }
}

export function useUnreadMessagesCount() {
  const { data } = useUnreadMessagesQuery();
  return data?.unreadMessagesCount;
}

export default function Messages() {
  const { user } = useUser({
    redirectTo: "/login",
  });
  const [sortBy, setSortBy] = useState("most recent");
  const [filterBy, setFilterBy] = useState();
  const unreadMessagesCount = useUnreadMessagesCount();
  const { loading, error, data, fetchMore } = useThreadsQuery({
    variables: {
      read: readFilter(filterBy),
      audience: audienceFilter(filterBy),
      orderBy: orderFilter(sortBy),
      first: 5,
    },
    fetchPolicy: "cache-and-network",
  });

  const router = useRouter();
  if (!user) return <UserLoading />;
  if (user?.userProfile?.isEvaluator) {
    router.push("/");
    return null;
  }

  if (error) return <ErrorGraphic />;
  return (
    <div>
      <div className="flex flex-col w-full p-6">
        <div className="flex w-full flex-col mb-6">
          <h1 className="text-4xl font-bold text-blue-title mb-0">
            Message Board
          </h1>
          <div className="flex justify-between flex-wrap text-black mb-4">
            <div>
              {user.lph && (
                <Select
                  placeholder={"Sort by"}
                  dropdownMatchSelectWidth={false}
                  onChange={(val) => setSortBy(val)}
                  value={sortBy}
                  className="mt-6 mr-6"
                  style={{ minWidth: 140 }}
                >
                  <Select.Option value="most recent">Most recent</Select.Option>
                  <Select.Option value="provider a-z">
                    Provider: A-Z
                  </Select.Option>
                </Select>
              )}
              {/* <Select
                placeholder={"Filter by"}
                dropdownMatchSelectWidth={false}
                allowClear
                onChange={(val) => setFilterBy(val)}
                value={filterBy}
                className="mt-4 mr-6"
                style={{ minWidth: 140 }}
              >
                <Select.Option value="public">Public</Select.Option>
                <Select.Option value="private">Private</Select.Option>
                <Select.Option value="read">Read</Select.Option>
                <Select.Option value="unread">Unread</Select.Option>
              </Select> */}
            </div>
          </div>
          <div className="my-4">
            <NewMessage count={data?.threads?.edges?.length} />
          </div>
          <div className="text-black">
            {unreadMessagesCount != null && (
              <div>
                {unreadMessagesCount > 0
                  ? `${unreadMessagesCount} unread messages`
                  : "You have no unread messages"}
              </div>
            )}
          </div>
        </div>
        <div>
          <List
            loading={loading}
            itemLayout={"vertical"}
            size={"large"}
            dataSource={data?.threads?.edges}
            className="bg-white border border-solid border-gray"
            renderItem={(edge) => (
              <Message item={edge.node} count={data?.threads?.edges?.length} />
            )}
          />
          {data?.threads?.pageInfo?.hasNextPage && (
            <div className="w-full mt-6 flex justify-center">
              <Button
                loading={loading}
                disabled={loading}
                onClick={() => {
                  fetchMore({
                    variables: {
                      after: last(data?.threads?.edges)?.cursor,
                    },
                  });
                }}
              >
                Load more
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
