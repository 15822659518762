import * as React from "react";
import { Button, Result } from "antd";
import Link from "next/link";

export const ErrorGraphic = () => {
  return (
    <div className="flex justify-center w-full">
      <Result
        status="500"
        title="Sorry, something went wrong."
        subTitle="Please try again later."
        extra={
          <Link href={"/"}>
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  );
};
