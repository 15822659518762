export function getToken() {
  if (process.browser) {
    const token = window?.localStorage?.getItem("auth_token");
    return token ? `Token ${token}` : "";
  }
  return "";
}

export default function client(
  endpoint: RequestInfo,
  {
    data,
    headers: customHeaders,
    ...customConfig
  }: RequestInit & { data?: any } = {}
) {
  const token = getToken();
  const config = {
    method: "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };
  return fetch(process.env.NEXT_PUBLIC_API_URL + endpoint, config).then(
    async (response) => {
      if (response.status === 401) {
        return Promise.reject({ message: "Please re-authenticate." });
      }
      let data = null;
      try {
        data = await response.json();
      } catch (err) {}
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    }
  );
}
