import * as React from "react";
import { Button, Upload } from "antd";
import FormItem from "./form-item";
import { FieldProps } from "formik";
import { getToken } from "utils/client";
import { last } from "lodash";

export const UploadDocument: React.FC = () => {
  return (
    <FormItem name="file">
      {({ field, form }: FieldProps) => {
        return (
          <Upload
            {...field}
            fileList={field.value || []}
            action={`${process.env.NEXT_PUBLIC_API_URL}/api/upload_document/`}
            headers={{
              Authorization: getToken(),
            }}
            onChange={(data) => {
              const file = last(data?.fileList);
              if (file) {
                form.setFieldValue(field.name, [
                  {
                    uid: file?.uid,
                    name: file?.name || file?.response?.name,
                    status: file?.status || file?.response?.status,
                    url: file?.url || file?.response?.url,
                  },
                ]);
              } else {
                form.setFieldValue(field.name, []);
              }
            }}
          >
            <Button>
              <div className="flex items-center">
                <UploadIcon />
                Upload document
              </div>
            </Button>
          </Upload>
        );
      }}
    </FormItem>
  );
};

const UploadIcon = () => {
  return (
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      className=""
      data-icon="upload"
      width="1rem"
      height="1rem"
      aria-hidden="true"
      fill="currentColor"
      style={{ marginRight: "0.5rem" }}
    >
      <path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163a8 8 0 00-12.6 0l-112 141.7c-4.1 5.3-.4 13 6.3 13zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z" />
    </svg>
  );
};
