import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { onError } from "@apollo/link-error";
import { setContext } from "@apollo/link-context";
import { getToken } from "utils/client";
import { relayStylePagination } from "@apollo/client/utilities";

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL + "/graphiql/",
  fetch: (...args) => fetch(...args),
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: getToken(),
    },
  };
});

const errorLink = onError(({ networkError }) => {
  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    localStorage.removeItem("auth_token");
    if (window.location.pathname !== "/login") {
      window.location.assign("/login");
    }
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          threads: relayStylePagination(),
          questionnaires: relayStylePagination((args) => {
            const keys = ["first"];
            if (args.submitted != null) {
              keys.push("submitted");
            }
            if (args.name != null) {
              keys.push("name");
            }
            return keys;
          }),
        },
      },
    },
  }),
  link: authLink.concat(errorLink).concat(httpLink),
});
