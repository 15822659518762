import * as React from "react";
import { Button, Input, Modal, Select, Alert } from "antd";
import { useState } from "react";
import { UploadDocument } from "./upload-document";
import { FieldProps, Form, Formik } from "formik";
import FormItem from "./form-item";
import {
  ThreadsDocument,
  useCreateMessageMutation,
  useProcurementUserProfilesQuery,
} from "generated/graphql";
import * as yup from "yup";
import { useUser } from "hooks/useAuth";
import fromErrors from "utils/from-errors";

export const schema = yup.object().shape({
  subject: yup.string().required("This field is required"),
  body: yup.string().required("This field is required"),
  file: yup.array(
    yup.object().shape({
      name: yup.string().required("This field is required"),
      url: yup.string().required("This field is required"),
    })
  ),
});

function SelectProvider() {
  const { data } = useProcurementUserProfilesQuery();
  return (
    <FormItem label={<div className="text-sm">Send to:</div>} name="sendTo">
      {({ field, form }: FieldProps) => {
        return (
          <Select
            {...field}
            allowClear
            className="w-full"
            onChange={(value) => {
              form.setFieldValue(field.name, value);
            }}
          >
            {data?.procurementUserProfiles?.map((userProfile) => (
              <Select.Option key={userProfile.id} value={userProfile.id}>
                {userProfile?.applicationName}
              </Select.Option>
            ))}
          </Select>
        );
      }}
    </FormItem>
  );
}

export const NewMessage = ({ count }) => {
  const { user } = useUser({});
  const [visible, setVisible] = useState(false);
  const [mutate, { loading }] = useCreateMessageMutation();

  const initialValues = {
    subject: "",
    body: "",
    sendTo: "",
    file: [],
  };
  return (
    <Alert
      showIcon
      banner
      type="info"
      message="Sending messages is no longer available"
      description={
        <span>
          To contact the LPH team, please send an email to{" "}
          <a href="mailto:lph.domcareaqp@nhs.net">lph.domcareaqp@nhs.net</a>
        </span>
      }
    />
  );

  return (
    <>
      <Modal
        width={800}
        visible={visible}
        getContainer="#root"
        onCancel={() => setVisible(!visible)}
        title="New message"
        footer={null}
      >
        <Formik
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={(values: any, { resetForm }) => {
            mutate({
              variables: {
                input: {
                  body: values.body,
                  subject: values.subject,
                  sendTo: values.sendTo,
                  attachment: values.file[0] && {
                    name: values.file[0]?.name,
                    url: values.file[0]?.url,
                  },
                },
              },
              refetchQueries: [
                {
                  query: ThreadsDocument,
                  variables: {
                    first: 1 + count,
                  },
                },
              ],
            }).then((res) => {
              setVisible(false);
              resetForm({ values: initialValues });
            });
          }}
        >
          <Form>
            <div className="text-black">
              <FormItem
                label={<div className="text-sm">Subject</div>}
                name="subject"
                as={Input}
              />
              <FormItem
                label={<div className="text-sm">Message</div>}
                name="body"
                as={Input.TextArea}
                rows={4}
              />
              <UploadDocument />
              {user.lph && <SelectProvider />}
            </div>
            <div className="flex flex-wrap justify-end items-center">
              <Button type="primary" htmlType="submit" loading={loading}>
                Send
              </Button>
            </div>
          </Form>
        </Formik>
      </Modal>
      <Button type={"primary"} onClick={() => setVisible(true)}>
        Send new message
      </Button>
    </>
  );
};
