import { useSectionList } from "hooks/user-section-list";
import moment from "moment-timezone";
import React from "react";

const DeadlineContext = React.createContext([]);

export function useDeadline() {
  const { data } = useSectionList();
  const [now, setNow] = React.useContext(DeadlineContext);
  const deadline = moment.tz("2020-09-25T17:00:00", "Europe/London");
  const daysRemaining = deadline.diff(now, "days");
  const minutesRemaining = deadline.diff(now, "minutes");
  const hoursRemaining = deadline.diff(now, "hours");

  const clarificationQuestionsDeadline =
    data?.questionnaire?.clarificationQuestionsDeadline &&
    moment(data?.questionnaire?.clarificationQuestionsDeadline);

  const clarificationQuestionsSubmittedDate =
    data?.questionnaire?.clarificationQuestionsSubmittedDate &&
    moment(data?.questionnaire?.clarificationQuestionsSubmittedDate);

  const clarificationQuestionsDeadlinePassed = clarificationQuestionsDeadline
    ? now > clarificationQuestionsDeadline
    : false;

  const clarificationQuestionsHoursRemaining = clarificationQuestionsDeadline?.diff(
    now,
    "hours"
  );

  return {
    deadlineExpired: now > deadline,
    deadline,
    resetTime: () => setNow(moment.tz(new Date(), "Europe/London")),
    daysRemaining,
    minutesRemaining,
    hoursRemaining,
    clarificationQuestionsPending:
      data?.questionnaire?.clarificationQuestionsPending,
    clarificationQuestionsDeadline,
    clarificationQuestionsSubmittedDate,
    clarificationQuestionsDeadlinePassed,
    clarificationQuestionsHoursRemaining,
  };
}

export function DeadlineContextProvider(props) {
  const [now, setNow] = React.useState(moment.tz(new Date(), "Europe/London"));
  const value = React.useMemo(() => [now, setNow], [now, setNow]);
  return <DeadlineContext.Provider {...props} value={value} />;
}
