import {
  FileOutlined,
  HomeOutlined,
  Loading3QuartersOutlined,
  CheckCircleOutlined,
  MailOutlined,
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useUser } from "hooks/useAuth";
import Link from "next/link";
import { useRouter } from "next/router";
import { useUnreadMessagesCount } from "pages/messages";
import React from "react";
import Transition from "./transition";
import { useEvaluatorTeamQuery } from "generated/graphql";
import { FormOutlined } from "@ant-design/icons/lib";

function MenuItem(props) {
  const router = useRouter();
  const active = (props.as || props.href) === router.asPath;

  return (
    <Link href={props.href} as={props.as}>
      <a
        className={`group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-white ${
          active ? "bg-primary-blue" : "bg-dark-blue hover:bg-gray-600"
        } focus:outline-none focus:shadow-focus transition ease-in-out duration-150`}
      >
        <span className="pr-4">{props.icon}</span>
        {props.title}
      </a>
    </Link>
  );
}

function Menu() {
  const { user } = useUser({});
  const unreadMessagesCount = useUnreadMessagesCount();

  if (user?.userProfile?.isEvaluator) {
    return <TechnicalEvaluationNavigation username={user?.username} />;
  }
  return (
    <>
      <MenuItem title="Home" href="/" icon={<HomeOutlined />} />
      {user.lph && (
        <MenuItem title="Teams" href="/teams" icon={<TeamOutlined />} />
      )}
      <MenuItem
        title="Offer documents"
        href="/documents"
        icon={<FileOutlined />}
      />
      <MenuItem
        title="Qualification questionnaire"
        href="/questionnaire/[uuid]/section/[section]"
        as={`/questionnaire/${user?.userProfile?.provideruserprofile?.domcareProcurement?.qualificationquestionnaire?.uuid}/section/1`}
        icon={<UserOutlined />}
      />
      <MenuItem
        title={
          <>
            Message board{" "}
            {user?.lph && (
              <span className="ml-auto inline-block py-0.5 px-3 text-xs leading-4 font-medium rounded-full bg-indigo-900 transition ease-in-out duration-150 group-hover:bg-indigo-800 group-focus:bg-indigo-800">
                {unreadMessagesCount || 0}
              </span>
            )}
          </>
        }
        href="/messages"
        icon={<MailOutlined />}
      />
      {!user?.lph &&
        user?.userProfile?.provideruserprofile?.domcareProcurement
          ?.qualificationquestionnaire?.contractIssued && (
          <MenuItem
            title="Contract"
            href="/contract/[uuid]"
            as={`/contract/${user?.userProfile?.provideruserprofile?.domcareProcurement?.legalEntity2021?.uuid}`}
            icon={<FormOutlined />}
          />
        )}
      <MenuItem title="Account" href="/account" icon={<SettingOutlined />} />
    </>
  );
}

function TechnicalEvaluationNavigation({ username }) {
  const { data } = useEvaluatorTeamQuery({
    variables: {
      username,
    },
  });
  const applications = data?.team?.applications;
  return (
    <>
      <MenuItem title="Home" href="/" icon={<HomeOutlined />} />
      {applications?.map((item) => {
        let iconData = item.qualificationquestionnaire.evaluatorStatus;
        return (
          <MenuItem
            key={item.applicationName}
            title={item.applicationName}
            href="/technical-evaluation/[uuid]"
            as={`/technical-evaluation/${item?.qualificationquestionnaire?.uuid}`}
            icon={
              iconData === "ready-for-moderation" ? (
                <CheckCircleOutlined />
              ) : iconData === "in-progress" ||
                iconData === "ready-to-evaluate" ? (
                <Loading3QuartersOutlined />
              ) : null
            }
          />
        );
      })}
      <MenuItem title="Account" href="/account" icon={<SettingOutlined />} />
    </>
  );
}

function DesktopNavigation() {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64 bg-dark-blue">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col h-0 flex-1">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <nav className="flex-1 px-2 bg-dark-blue space-y-1">
              <Menu />
            </nav>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

function Footer() {
  return (
    <div className="flex-shrink-0 flex flex-col p-4 text-white text-xs">
      <Link href="/privacy_policy">
        <a
          className="text-white hover:underline focus:underline"
          target="_blank"
        >
          Privacy policy
        </a>
      </Link>
      <Link href="/terms_of_use">
        <a
          className="text-white hover:underline focus:underline"
          target="_blank"
        >
          Terms of use
        </a>
      </Link>
      <div className="mt-4">
        CarePulse™ is powered by Evalucom Consulting Ltd. Designed and built in
        the United Kingdom.
      </div>
    </div>
  );
}

function MobileNavigation({
  hideSidebarMenu,
  hideSidebar,
  isSidebarOpen,
  closeSidebar,
}) {
  return (
    <div className={`${hideSidebarMenu ? "hidden " : ""}md:hidden`}>
      <div className="fixed inset-0 flex z-40">
        <Transition
          show={isSidebarOpen}
          onExited={hideSidebar}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" onClick={closeSidebar}>
            <div className="absolute inset-0 bg-gray-600 opacity-75"></div>
          </div>
        </Transition>
        <Transition
          show={isSidebarOpen}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full bg-dark-blue">
            <div className="flex-1 h-0 pb-4 overflow-y-auto">
              <nav className="mt-5 px-2 space-y-1">
                <Menu />
              </nav>
            </div>
            <Footer />
          </div>
        </Transition>
        <div className="flex-shrink-0 w-14">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </div>
    </div>
  );
}

export default function Navigation({
  isSidebarOpen,
  hideSidebarMenu,
  closeSidebar,
  hideSidebar,
}) {
  return (
    <>
      <MobileNavigation
        isSidebarOpen={isSidebarOpen}
        hideSidebarMenu={hideSidebarMenu}
        closeSidebar={closeSidebar}
        hideSidebar={hideSidebar}
      />
      <DesktopNavigation />
    </>
  );
}
