import ReactGA from "react-ga";

export const initGA = () => {
  console.log("GA init");
  ReactGA.initialize("UA-92277524-2");
};
export const logPageView = (url) => {
  console.log(`Logging pageview for ${url || window.location.pathname}`);
  ReactGA.set({ page: url || window.location.pathname });
  ReactGA.pageview(url || window.location.pathname);
};
export const logEvent = (event: ReactGA.EventArgs) => {
  if (event) {
    ReactGA.event(event);
  }
};
export const logException = (description = "", fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
