import { useUser } from "../hooks/useAuth";
import { Button } from "antd";
import Link from "next/link";
import { MenuOutlined } from "@ant-design/icons";

export function NHSLogo() {
  return (
    <svg
      width="198"
      height="58"
      viewBox="0 0 198 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M198 32.7106V0.771973H127.762V32.7106H198ZM156.001 3.85881L151.152 29.5674H143.538L138.758 11.7674H138.699L135.515 29.5561H129.748L134.637 3.84754H142.271L146.961 21.6701H147.02L150.234 3.84754H156.001V3.85881ZM177.764 3.85881L173.014 29.5674H166.887L168.923 18.5607H161.679L159.643 29.5674H153.506L158.256 3.85881H164.383L162.587 13.6939H169.831L171.637 3.85881H177.764ZM195.376 4.55729L193.899 9.67197C192.721 9.05235 191.115 8.50033 188.85 8.50033C186.425 8.50033 184.459 8.9059 184.459 10.9675C184.459 14.6064 193.34 13.2545 193.34 21.0617C193.34 28.1705 187.473 30.0068 182.164 30.0068C179.799 30.0068 177.085 29.3759 175.09 28.6774L176.537 23.4501C177.744 24.3401 180.179 24.9259 182.174 24.9259C184.08 24.9259 187.054 24.5203 187.054 21.8729C187.054 17.7496 178.173 19.293 178.173 12.0491C178.173 5.41349 183.352 3.43071 188.371 3.43071C191.175 3.40817 193.829 3.74615 195.376 4.55729Z"
        fill="#005EB8"
      />
      <path
        d="M0.988281 57.1014V48.2548H2.65332V55.5523H5.83838V57.1014H0.988281Z"
        fill="black"
      />
      <path
        d="M8.38428 53.7068C8.38428 54.3764 8.48096 54.8827 8.67432 55.2256C8.87126 55.5684 9.18994 55.7399 9.63037 55.7399C10.0672 55.7399 10.3805 55.5705 10.5703 55.2316C10.7637 54.8887 10.8604 54.3804 10.8604 53.7068C10.8604 53.0371 10.7637 52.5349 10.5703 52.2001C10.377 51.8652 10.0601 51.6978 9.61963 51.6978C9.18278 51.6978 8.86768 51.8652 8.67432 52.2001C8.48096 52.5309 8.38428 53.0331 8.38428 53.7068ZM12.5361 53.7068C12.5361 54.808 12.2783 55.6693 11.7627 56.2905C11.2471 56.9118 10.5291 57.2224 9.60889 57.2224C9.03239 57.2224 8.52393 57.0812 8.0835 56.7988C7.64307 56.5124 7.30469 56.103 7.06836 55.5705C6.83203 55.038 6.71387 54.4167 6.71387 53.7068C6.71387 52.6014 6.96989 51.7422 7.48193 51.129C7.99398 50.5159 8.7137 50.2093 9.64111 50.2093C10.2176 50.2093 10.7261 50.3505 11.1665 50.6329C11.6069 50.9152 11.9453 51.3207 12.1816 51.8491C12.418 52.3776 12.5361 52.9968 12.5361 53.7068Z"
        fill="black"
      />
      <path
        d="M19.4326 57.1014H17.7944V53.1501C17.7944 52.662 17.7174 52.2969 17.5635 52.0548C17.4095 51.8088 17.1642 51.6857 16.8276 51.6857C16.3693 51.6857 16.0381 51.8592 15.834 52.2061C15.6299 52.549 15.5278 53.1198 15.5278 53.9185V57.1014H13.8896V50.3364H15.1411L15.3613 51.2017H15.4526C15.6353 50.8749 15.8859 50.6288 16.2046 50.4634C16.5269 50.294 16.8921 50.2093 17.3003 50.2093C17.9985 50.2093 18.5285 50.4231 18.8901 50.8507C19.2518 51.2743 19.4326 51.8874 19.4326 52.6902V57.1014Z"
        fill="black"
      />
      <path
        d="M23.0205 57.2224C22.3151 57.2224 21.7601 56.9138 21.3555 56.2966C20.9544 55.6794 20.7539 54.8242 20.7539 53.731C20.7539 52.6216 20.958 51.7583 21.3662 51.1411C21.778 50.5199 22.3438 50.2093 23.0635 50.2093C23.819 50.2093 24.3955 50.5401 24.793 51.2017H24.8467C24.7643 50.6974 24.7231 50.2476 24.7231 49.8523V47.686H26.3667V57.1014H25.1099L24.793 56.224H24.7231C24.3507 56.8896 23.7832 57.2224 23.0205 57.2224ZM23.5952 55.752C24.0142 55.752 24.3203 55.6148 24.5137 55.3405C24.7106 55.0662 24.818 54.6003 24.8359 53.9427V53.7431C24.8359 53.0169 24.7357 52.4966 24.5352 52.1819C24.3382 51.8673 24.016 51.7099 23.5684 51.7099C23.2031 51.7099 22.9185 51.8854 22.7144 52.2364C22.5138 52.5833 22.4136 53.0896 22.4136 53.7552C22.4136 54.4208 22.5156 54.921 22.7197 55.2558C22.9238 55.5866 23.2157 55.752 23.5952 55.752Z"
        fill="black"
      />
      <path
        d="M29.3853 53.7068C29.3853 54.3764 29.4819 54.8827 29.6753 55.2256C29.8722 55.5684 30.1909 55.7399 30.6313 55.7399C31.0682 55.7399 31.3815 55.5705 31.5713 55.2316C31.7646 54.8887 31.8613 54.3804 31.8613 53.7068C31.8613 53.0371 31.7646 52.5349 31.5713 52.2001C31.3779 51.8652 31.061 51.6978 30.6206 51.6978C30.1838 51.6978 29.8687 51.8652 29.6753 52.2001C29.4819 52.5309 29.3853 53.0331 29.3853 53.7068ZM33.5371 53.7068C33.5371 54.808 33.2793 55.6693 32.7637 56.2905C32.248 56.9118 31.5301 57.2224 30.6099 57.2224C30.0334 57.2224 29.5249 57.0812 29.0845 56.7988C28.644 56.5124 28.3057 56.103 28.0693 55.5705C27.833 55.038 27.7148 54.4167 27.7148 53.7068C27.7148 52.6014 27.9709 51.7422 28.4829 51.129C28.995 50.5159 29.7147 50.2093 30.6421 50.2093C31.2186 50.2093 31.7271 50.3505 32.1675 50.6329C32.6079 50.9152 32.9463 51.3207 33.1826 51.8491C33.4189 52.3776 33.5371 52.9968 33.5371 53.7068Z"
        fill="black"
      />
      <path
        d="M40.4336 57.1014H38.7954V53.1501C38.7954 52.662 38.7184 52.2969 38.5645 52.0548C38.4105 51.8088 38.1652 51.6857 37.8286 51.6857C37.3703 51.6857 37.0391 51.8592 36.835 52.2061C36.6309 52.549 36.5288 53.1198 36.5288 53.9185V57.1014H34.8906V50.3364H36.1421L36.3623 51.2017H36.4536C36.6362 50.8749 36.8869 50.6288 37.2056 50.4634C37.5278 50.294 37.8931 50.2093 38.3013 50.2093C38.9995 50.2093 39.5295 50.4231 39.8911 50.8507C40.2528 51.2743 40.4336 51.8874 40.4336 52.6902V57.1014Z"
        fill="black"
      />
      <path
        d="M46.7715 52.4179H47.3193C47.8314 52.4179 48.2145 52.305 48.4688 52.079C48.723 51.8491 48.8501 51.5163 48.8501 51.0806C48.8501 50.6409 48.7427 50.3162 48.5278 50.1064C48.3166 49.8967 47.9836 49.7918 47.5288 49.7918H46.7715V52.4179ZM50.5312 51.0141C50.5312 51.9661 50.2663 52.6942 49.7363 53.1985C49.21 53.7027 48.4598 53.9549 47.4858 53.9549H46.7715V57.1014H45.1064V48.2548H47.6147C48.5672 48.2548 49.2905 48.4868 49.7847 48.9507C50.2824 49.4106 50.5312 50.0984 50.5312 51.0141Z"
        fill="black"
      />
      <path
        d="M56.1387 57.1014L55.9185 56.2361H55.8325C55.6571 56.5507 55.4082 56.7948 55.0859 56.9682C54.7637 57.1377 54.3966 57.2224 53.9849 57.2224C53.2795 57.2224 52.7477 57.0106 52.3896 56.587C52.0316 56.1594 51.8525 55.5463 51.8525 54.7475V50.3364H53.4907V54.2877C53.4907 54.7758 53.5677 55.1429 53.7217 55.3889C53.8757 55.631 54.1209 55.752 54.4575 55.752C54.9159 55.752 55.2471 55.5805 55.4512 55.2377C55.6553 54.8907 55.7573 54.3179 55.7573 53.5192V50.3364H57.3955V57.1014H56.1387Z"
        fill="black"
      />
      <path
        d="M62.4658 50.2093C62.6878 50.2093 62.8722 50.2274 63.019 50.2638L62.8955 51.9943C62.763 51.954 62.6019 51.9338 62.4121 51.9338C61.8893 51.9338 61.4811 52.0851 61.1875 52.3876C60.8975 52.6902 60.7524 53.1138 60.7524 53.6584V57.1014H59.1143V50.3364H60.355L60.5967 51.4739H60.6772C60.8634 51.0948 61.1141 50.7902 61.4292 50.5603C61.7479 50.3263 62.0934 50.2093 62.4658 50.2093Z"
        fill="black"
      />
      <path
        d="M66.4404 57.2224C64.5713 57.2224 63.6367 56.0666 63.6367 53.7552C63.6367 52.6055 63.8909 51.7281 64.3994 51.123C64.9079 50.5139 65.6366 50.2093 66.5854 50.2093C67.2801 50.2093 67.9032 50.3626 68.4546 50.6692L67.9712 52.0972C67.7134 51.9802 67.4735 51.8854 67.2515 51.8128C67.0295 51.7362 66.8075 51.6978 66.5854 51.6978C65.7332 51.6978 65.3071 52.3796 65.3071 53.7431C65.3071 55.0662 65.7332 55.7278 66.5854 55.7278C66.9006 55.7278 67.1924 55.6814 67.4609 55.5886C67.7295 55.4918 67.998 55.3425 68.2666 55.1408V56.7202C68.0016 56.9097 67.7331 57.0409 67.4609 57.1135C67.1924 57.1861 66.8522 57.2224 66.4404 57.2224Z"
        fill="black"
      />
      <path
        d="M75.2061 57.1014H73.5679V53.1501C73.5679 52.1738 73.2456 51.6857 72.6011 51.6857C72.1427 51.6857 71.8115 51.8612 71.6074 52.2122C71.4033 52.5631 71.3013 53.1319 71.3013 53.9185V57.1014H69.6631V47.686H71.3013V49.6042C71.3013 49.7535 71.2887 50.1044 71.2637 50.6571L71.2261 51.2017H71.312C71.6772 50.5401 72.2573 50.2093 73.0522 50.2093C73.7577 50.2093 74.293 50.4231 74.6582 50.8507C75.0234 51.2783 75.2061 51.8915 75.2061 52.6902V57.1014Z"
        fill="black"
      />
      <path
        d="M80.7061 57.1014L80.3892 56.1816H80.3462C80.0705 56.5729 79.7858 56.8452 79.4922 56.9985C79.2021 57.1478 78.8226 57.2224 78.3535 57.2224C77.777 57.2224 77.3223 57.0368 76.9893 56.6657C76.6598 56.2946 76.4951 55.7661 76.4951 55.0803C76.4951 54.3623 76.7171 53.8338 77.1611 53.495C77.6087 53.1521 78.2819 52.9625 79.1807 52.9262L80.2227 52.8899V52.5934C80.2227 51.9076 79.9111 51.5647 79.2881 51.5647C78.8083 51.5647 78.2443 51.7281 77.5962 52.0548L77.0537 50.8083C77.7448 50.4009 78.5111 50.1972 79.3525 50.1972C80.1582 50.1972 80.7759 50.3949 81.2056 50.7902C81.6353 51.1855 81.8501 51.7866 81.8501 52.5934V57.1014H80.7061ZM80.2227 53.967L79.5889 53.9912C79.1126 54.0073 78.7581 54.1041 78.5254 54.2816C78.2926 54.4591 78.1763 54.7294 78.1763 55.0924C78.1763 55.6128 78.4412 55.873 78.9712 55.873C79.3507 55.873 79.6533 55.75 79.8789 55.5039C80.1081 55.2578 80.2227 54.9311 80.2227 54.5236V53.967Z"
        fill="black"
      />
      <path
        d="M87.7314 55.0924C87.7314 55.7863 87.5166 56.3147 87.0869 56.6778C86.6608 57.0409 86.0216 57.2224 85.1694 57.2224C84.7326 57.2224 84.3602 57.1881 84.0522 57.1195C83.7443 57.055 83.4561 56.9582 83.1875 56.8291V55.3042C83.4919 55.4656 83.8338 55.6007 84.2134 55.7096C84.5965 55.8186 84.9331 55.873 85.2231 55.873C85.8175 55.873 86.1147 55.6794 86.1147 55.2921C86.1147 55.1469 86.0754 55.0299 85.9966 54.9412C85.9178 54.8484 85.7817 54.7455 85.5884 54.6326C85.395 54.5156 85.1372 54.3804 84.8149 54.2271C84.353 54.0093 84.0129 53.8076 83.7944 53.622C83.5796 53.4365 83.422 53.2247 83.3218 52.9867C83.2251 52.7447 83.1768 52.4482 83.1768 52.0972C83.1768 51.4961 83.3827 51.0322 83.7944 50.7055C84.2098 50.3747 84.797 50.2093 85.5562 50.2093C86.2795 50.2093 86.9831 50.3868 87.667 50.7418L87.1729 52.073C86.8721 51.9278 86.591 51.8088 86.3296 51.716C86.0682 51.6232 85.8014 51.5768 85.5293 51.5768C85.0459 51.5768 84.8042 51.7241 84.8042 52.0185C84.8042 52.1839 84.8812 52.3271 85.0352 52.4482C85.1927 52.5692 85.5347 52.7487 86.061 52.9867C86.5301 53.2005 86.8739 53.4002 87.0923 53.5857C87.3107 53.7713 87.4718 53.9851 87.5757 54.2271C87.6795 54.4692 87.7314 54.7576 87.7314 55.0924Z"
        fill="black"
      />
      <path
        d="M91.4966 51.5224C91.1493 51.5224 90.8771 51.6474 90.6802 51.8975C90.4832 52.1436 90.3704 52.4945 90.3418 52.9504H92.6406C92.6335 52.4945 92.5278 52.1436 92.3237 51.8975C92.1196 51.6474 91.8439 51.5224 91.4966 51.5224ZM91.7275 57.2224C90.7607 57.2224 90.0052 56.9219 89.4609 56.3208C88.9167 55.7197 88.6445 54.8686 88.6445 53.7673C88.6445 52.6337 88.8952 51.7583 89.3965 51.1411C89.9014 50.5199 90.5978 50.2093 91.4858 50.2093C92.3345 50.2093 92.9951 50.4816 93.4678 51.0262C93.9404 51.5708 94.1768 52.3231 94.1768 53.2832V54.1787H90.3042C90.3221 54.7032 90.46 55.1126 90.7178 55.4071C90.9756 55.7016 91.3372 55.8488 91.8027 55.8488C92.1644 55.8488 92.5063 55.8065 92.8286 55.7217C93.1509 55.637 93.4875 55.5019 93.8384 55.3163V56.7444C93.5519 56.9057 93.2458 57.0247 92.9199 57.1014C92.5941 57.182 92.1966 57.2224 91.7275 57.2224Z"
        fill="black"
      />
      <path
        d="M97.4102 57.2224C96.7048 57.2224 96.1497 56.9138 95.7451 56.2966C95.3441 55.6794 95.1436 54.8242 95.1436 53.731C95.1436 52.6216 95.3477 51.7583 95.7559 51.1411C96.1676 50.5199 96.7334 50.2093 97.4531 50.2093C98.2087 50.2093 98.7852 50.5401 99.1826 51.2017H99.2363C99.154 50.6974 99.1128 50.2476 99.1128 49.8523V47.686H100.756V57.1014H99.4995L99.1826 56.224H99.1128C98.7404 56.8896 98.1729 57.2224 97.4102 57.2224ZM97.9849 55.752C98.4038 55.752 98.71 55.6148 98.9033 55.3405C99.1003 55.0662 99.2077 54.6003 99.2256 53.9427V53.7431C99.2256 53.0169 99.1253 52.4966 98.9248 52.1819C98.7279 51.8673 98.4056 51.7099 97.958 51.7099C97.5928 51.7099 97.3081 51.8854 97.104 52.2364C96.9035 52.5833 96.8032 53.0896 96.8032 53.7552C96.8032 54.4208 96.9053 54.921 97.1094 55.2558C97.3135 55.5866 97.6053 55.752 97.9849 55.752Z"
        fill="black"
      />
      <path
        d="M111.891 57.1014H110.231V53.2832H107.121V57.1014H105.456V48.2548H107.121V51.722H110.231V48.2548H111.891V57.1014Z"
        fill="black"
      />
      <path
        d="M116.236 51.5224C115.889 51.5224 115.616 51.6474 115.419 51.8975C115.222 52.1436 115.11 52.4945 115.081 52.9504H117.38C117.373 52.4945 117.267 52.1436 117.063 51.8975C116.859 51.6474 116.583 51.5224 116.236 51.5224ZM116.467 57.2224C115.5 57.2224 114.744 56.9219 114.2 56.3208C113.656 55.7197 113.384 54.8686 113.384 53.7673C113.384 52.6337 113.634 51.7583 114.136 51.1411C114.641 50.5199 115.337 50.2093 116.225 50.2093C117.074 50.2093 117.734 50.4816 118.207 51.0262C118.68 51.5708 118.916 52.3231 118.916 53.2832V54.1787H115.043C115.061 54.7032 115.199 55.1126 115.457 55.4071C115.715 55.7016 116.076 55.8488 116.542 55.8488C116.904 55.8488 117.246 55.8065 117.568 55.7217C117.89 55.637 118.227 55.5019 118.578 55.3163V56.7444C118.291 56.9057 117.985 57.0247 117.659 57.1014C117.333 57.182 116.936 57.2224 116.467 57.2224Z"
        fill="black"
      />
      <path
        d="M124.062 57.1014L123.745 56.1816H123.702C123.426 56.5729 123.141 56.8452 122.848 56.9985C122.558 57.1478 122.178 57.2224 121.709 57.2224C121.132 57.2224 120.678 57.0368 120.345 56.6657C120.015 56.2946 119.851 55.7661 119.851 55.0803C119.851 54.3623 120.073 53.8338 120.517 53.495C120.964 53.1521 121.637 52.9625 122.536 52.9262L123.578 52.8899V52.5934C123.578 51.9076 123.267 51.5647 122.644 51.5647C122.164 51.5647 121.6 51.7281 120.952 52.0548L120.409 50.8083C121.1 50.4009 121.867 50.1972 122.708 50.1972C123.514 50.1972 124.131 50.3949 124.561 50.7902C124.991 51.1855 125.206 51.7866 125.206 52.5934V57.1014H124.062ZM123.578 53.967L122.944 53.9912C122.468 54.0073 122.114 54.1041 121.881 54.2816C121.648 54.4591 121.532 54.7294 121.532 55.0924C121.532 55.6128 121.797 55.873 122.327 55.873C122.706 55.873 123.009 55.75 123.234 55.5039C123.464 55.2578 123.578 54.9311 123.578 54.5236V53.967Z"
        fill="black"
      />
      <path d="M128.536 57.1014H126.897V47.686H128.536V57.1014Z" fill="black" />
      <path
        d="M132.79 55.752C133.076 55.752 133.42 55.6814 133.821 55.5402V56.9138C133.413 57.1195 132.911 57.2224 132.317 57.2224C131.662 57.2224 131.184 57.0368 130.883 56.6657C130.586 56.2905 130.437 55.7298 130.437 54.9835V51.722H129.653V50.9415L130.555 50.3243L131.028 48.8962H132.075V50.3364H133.756V51.722H132.075V54.9835C132.075 55.2457 132.14 55.4394 132.269 55.5644C132.401 55.6895 132.575 55.752 132.79 55.752Z"
        fill="black"
      />
      <path
        d="M140.583 57.1014H138.945V53.1501C138.945 52.1738 138.623 51.6857 137.978 51.6857C137.52 51.6857 137.188 51.8612 136.984 52.2122C136.78 52.5631 136.678 53.1319 136.678 53.9185V57.1014H135.04V47.686H136.678V49.6042C136.678 49.7535 136.666 50.1044 136.641 50.6571L136.603 51.2017H136.689C137.054 50.5401 137.634 50.2093 138.429 50.2093C139.135 50.2093 139.67 50.4231 140.035 50.8507C140.4 51.2783 140.583 51.8915 140.583 52.6902V57.1014Z"
        fill="black"
      />
      <path
        d="M144.708 57.2224C142.839 57.2224 141.904 56.0666 141.904 53.7552C141.904 52.6055 142.159 51.7281 142.667 51.123C143.175 50.5139 143.904 50.2093 144.853 50.2093C145.548 50.2093 146.171 50.3626 146.722 50.6692L146.239 52.0972C145.981 51.9802 145.741 51.8854 145.519 51.8128C145.297 51.7362 145.075 51.6978 144.853 51.6978C144.001 51.6978 143.575 52.3796 143.575 53.7431C143.575 55.0662 144.001 55.7278 144.853 55.7278C145.168 55.7278 145.46 55.6814 145.729 55.5886C145.997 55.4918 146.266 55.3425 146.534 55.1408V56.7202C146.269 56.9097 146.001 57.0409 145.729 57.1135C145.46 57.1861 145.12 57.2224 144.708 57.2224Z"
        fill="black"
      />
      <path
        d="M151.744 57.1014L151.427 56.1816H151.384C151.109 56.5729 150.824 56.8452 150.53 56.9985C150.24 57.1478 149.861 57.2224 149.392 57.2224C148.815 57.2224 148.36 57.0368 148.027 56.6657C147.698 56.2946 147.533 55.7661 147.533 55.0803C147.533 54.3623 147.755 53.8338 148.199 53.495C148.647 53.1521 149.32 52.9625 150.219 52.9262L151.261 52.8899V52.5934C151.261 51.9076 150.949 51.5647 150.326 51.5647C149.846 51.5647 149.282 51.7281 148.634 52.0548L148.092 50.8083C148.783 50.4009 149.549 50.1972 150.391 50.1972C151.196 50.1972 151.814 50.3949 152.244 50.7902C152.673 51.1855 152.888 51.7866 152.888 52.5934V57.1014H151.744ZM151.261 53.967L150.627 53.9912C150.151 54.0073 149.796 54.1041 149.563 54.2816C149.331 54.4591 149.214 54.7294 149.214 55.0924C149.214 55.6128 149.479 55.873 150.009 55.873C150.389 55.873 150.691 55.75 150.917 55.5039C151.146 55.2578 151.261 54.9311 151.261 54.5236V53.967Z"
        fill="black"
      />
      <path
        d="M157.932 50.2093C158.154 50.2093 158.338 50.2274 158.485 50.2638L158.361 51.9943C158.229 51.954 158.068 51.9338 157.878 51.9338C157.355 51.9338 156.947 52.0851 156.653 52.3876C156.363 52.6902 156.218 53.1138 156.218 53.6584V57.1014H154.58V50.3364H155.821L156.062 51.4739H156.143C156.329 51.0948 156.58 50.7902 156.895 50.5603C157.214 50.3263 157.559 50.2093 157.932 50.2093Z"
        fill="black"
      />
      <path
        d="M161.955 51.5224C161.607 51.5224 161.335 51.6474 161.138 51.8975C160.941 52.1436 160.828 52.4945 160.8 52.9504H163.099C163.091 52.4945 162.986 52.1436 162.782 51.8975C162.578 51.6474 162.302 51.5224 161.955 51.5224ZM162.186 57.2224C161.219 57.2224 160.463 56.9219 159.919 56.3208C159.375 55.7197 159.103 54.8686 159.103 53.7673C159.103 52.6337 159.353 51.7583 159.854 51.1411C160.359 50.5199 161.056 50.2093 161.944 50.2093C162.792 50.2093 163.453 50.4816 163.926 51.0262C164.398 51.5708 164.635 52.3231 164.635 53.2832V54.1787H160.762C160.78 54.7032 160.918 55.1126 161.176 55.4071C161.434 55.7016 161.795 55.8488 162.261 55.8488C162.622 55.8488 162.964 55.8065 163.287 55.7217C163.609 55.637 163.945 55.5019 164.296 55.3163V56.7444C164.01 56.9057 163.704 57.0247 163.378 57.1014C163.052 57.182 162.655 57.2224 162.186 57.2224Z"
        fill="black"
      />
      <path
        d="M171.982 57.1014H170.317V49.816H168.185V48.2548H174.115V49.816H171.982V57.1014Z"
        fill="black"
      />
      <path
        d="M177.305 51.5224C176.958 51.5224 176.686 51.6474 176.489 51.8975C176.292 52.1436 176.179 52.4945 176.15 52.9504H178.449C178.442 52.4945 178.336 52.1436 178.132 51.8975C177.928 51.6474 177.653 51.5224 177.305 51.5224ZM177.536 57.2224C176.569 57.2224 175.814 56.9219 175.27 56.3208C174.725 55.7197 174.453 54.8686 174.453 53.7673C174.453 52.6337 174.704 51.7583 175.205 51.1411C175.71 50.5199 176.406 50.2093 177.294 50.2093C178.143 50.2093 178.804 50.4816 179.276 51.0262C179.749 51.5708 179.985 52.3231 179.985 53.2832V54.1787H176.113C176.131 54.7032 176.269 55.1126 176.526 55.4071C176.784 55.7016 177.146 55.8488 177.611 55.8488C177.973 55.8488 178.315 55.8065 178.637 55.7217C178.959 55.637 179.296 55.5019 179.647 55.3163V56.7444C179.361 56.9057 179.054 57.0247 178.729 57.1014C178.403 57.182 178.005 57.2224 177.536 57.2224Z"
        fill="black"
      />
      <path
        d="M185.131 57.1014L184.814 56.1816H184.771C184.495 56.5729 184.211 56.8452 183.917 56.9985C183.627 57.1478 183.247 57.2224 182.778 57.2224C182.202 57.2224 181.747 57.0368 181.414 56.6657C181.085 56.2946 180.92 55.7661 180.92 55.0803C180.92 54.3623 181.142 53.8338 181.586 53.495C182.034 53.1521 182.707 52.9625 183.605 52.9262L184.647 52.8899V52.5934C184.647 51.9076 184.336 51.5647 183.713 51.5647C183.233 51.5647 182.669 51.7281 182.021 52.0548L181.479 50.8083C182.17 50.4009 182.936 50.1972 183.777 50.1972C184.583 50.1972 185.201 50.3949 185.63 50.7902C186.06 51.1855 186.275 51.7866 186.275 52.5934V57.1014H185.131ZM184.647 53.967L184.014 53.9912C183.537 54.0073 183.183 54.1041 182.95 54.2816C182.717 54.4591 182.601 54.7294 182.601 55.0924C182.601 55.6128 182.866 55.873 183.396 55.873C183.776 55.873 184.078 55.75 184.304 55.5039C184.533 55.2578 184.647 54.9311 184.647 54.5236V53.967Z"
        fill="black"
      />
      <path
        d="M193.343 57.1014H191.705V53.1501C191.705 52.662 191.632 52.2969 191.485 52.0548C191.342 51.8088 191.114 51.6857 190.803 51.6857C190.384 51.6857 190.079 51.8592 189.89 52.2061C189.7 52.553 189.605 53.1239 189.605 53.9185V57.1014H187.967V50.3364H189.218L189.438 51.2017H189.53C189.691 50.891 189.924 50.649 190.228 50.4755C190.532 50.298 190.882 50.2093 191.275 50.2093C192.174 50.2093 192.783 50.5401 193.102 51.2017H193.247C193.408 50.887 193.644 50.6429 193.956 50.4695C194.271 50.296 194.625 50.2093 195.019 50.2093C195.699 50.2093 196.213 50.407 196.561 50.8023C196.911 51.1936 197.087 51.8229 197.087 52.6902V57.1014H195.443V53.1501C195.443 52.662 195.37 52.2969 195.223 52.0548C195.08 51.8088 194.853 51.6857 194.541 51.6857C194.14 51.6857 193.839 51.8471 193.639 52.1698C193.442 52.4925 193.343 53.0048 193.343 53.7068V57.1014Z"
        fill="black"
      />
    </svg>
  );
}

export function CarePulseLogo() {
  return (
    <svg width="179" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M40.18 1.322C39.385.44 38.191 0 36.605 0H20.814v7.451H32.84c.478 0 .716.258.716.773v13.685c0 .297-.044.505-.139.633-.094.13-.308.193-.636.193H20.813v17.27h7.98V29.35h6.873c1.108 0 2.028-.139 2.769-.41.74-.278 1.328-.654 1.77-1.13.443-.48.746-1.04.915-1.683.17-.644.249-1.352.249-2.124V4.857c0-1.475-.398-2.649-1.189-3.535z"
          fill="#777"
        ></path>
        <path
          d="M8.975 32.553c-.482 0-.721-.257-.721-.772V8.22c0-.515.239-.773.72-.773h10.472V0H4.71C3.376 0 2.262.42 1.357 1.267.452 2.114 0 3.332 0 4.912V33.82c0 2.208.462 3.793 1.387 4.748.92.956 2.546 1.436 4.878 1.436h13.186v-7.452H8.975z"
          fill="#024DA1"
        ></path>
        <path
          d="M53.724.817s.105.59.234 1.624c.105 1.03.319 2.51.453 4.278.154 1.772.283 3.837.368 6.045.03 1.104.104 2.248.104 3.411.015 1.159.025 2.342.04 3.52-.015 1.179-.03 2.362-.04 3.52-.005 1.164-.07 2.308-.104 3.412a126.277 126.277 0 01-.368 6.045c-.135 1.768-.353 3.243-.453 4.278-.05.515-.129.921-.169 1.198-.045.277-.065.426-.065.426h-.144l-.07-18.878.07-18.88h.145z"
          fill="url(#paint0_linear)"
        ></path>
        <path
          d="M76.328 29.127c-3.605 0-6.484-1.213-8.557-3.604-1.75-2.01-2.635-4.476-2.635-7.333 0-3.099 1.099-5.698 3.272-7.728 2.118-1.98 4.778-2.986 7.895-2.986 3.919 0 6.962 1.466 9.055 4.347l.194.273-2.119 1.619-.208-.273c-1.696-2.188-4.018-3.302-6.902-3.302a8.698 8.698 0 00-4.211 1.07c-1.328.717-2.372 1.712-3.093 2.955-.726 1.243-1.094 2.659-1.094 4.199 0 2.381.826 4.371 2.456 5.901 1.606 1.51 3.65 2.278 6.067 2.278 2.69 0 4.977-1.06 6.792-3.154l.213-.242 2.119 1.599-.22.277c-2.152 2.718-5.185 4.104-9.024 4.104zM94.318 28.944c-1.904 0-3.525-.693-4.833-2.06-1.283-1.336-1.934-2.98-1.934-4.886 0-1.882.666-3.515 1.979-4.852 1.317-1.342 2.943-2.02 4.838-2.02 1.844 0 3.406.673 4.644 2v-1.679h2.292v13.175h-2.292v-1.604c-1.313 1.278-2.89 1.926-4.694 1.926zm.179-11.57c-1.8 0-3.103.762-3.988 2.331a4.695 4.695 0 00-.636 2.352c0 .817.214 1.614.636 2.357a4.638 4.638 0 001.696 1.738 4.476 4.476 0 002.277.624c.82 0 1.606-.208 2.337-.62.73-.41 1.303-.97 1.69-1.668.398-.703.597-1.505.597-2.386 0-1.332-.442-2.466-1.312-3.372-.876-.911-1.954-1.357-3.297-1.357zM103.626 28.622v-13.17h2.347v1.169c.88-.99 1.855-1.49 2.914-1.49.497 0 1.034.158 1.596.47l.313.173-1.183 1.921-.274-.119c-.293-.123-.537-.188-.716-.188-1.124 0-1.894.802-2.357 2.456-.189.678-.288 2.1-.288 4.218v4.56h-2.352zM117.608 28.944c-1.969 0-3.625-.658-4.918-1.96-1.292-1.303-1.949-2.956-1.949-4.912a7.02 7.02 0 011.537-4.446c1.297-1.659 3.063-2.495 5.235-2.495 2.238 0 4.053.856 5.39 2.55.95 1.203 1.442 2.713 1.457 4.48v.347h-11.242c.089 1.153.492 2.134 1.203 2.92.831.912 1.86 1.353 3.153 1.353 1.263 0 2.297-.372 3.172-1.13.393-.34.83-.91 1.303-1.688l.169-.277 1.949 1.02-.154.302c-.88 1.718-2.128 2.892-3.7 3.496-.77.292-1.646.44-2.605.44zm4.186-8.526c-.407-1.247-1.153-2.109-2.257-2.619a4.732 4.732 0 00-1.989-.435c-1.138 0-2.123.366-2.943 1.089-.508.45-.92 1.114-1.224 1.97h8.413v-.005z"
          fill="#024DA1"
        ></path>
        <path
          d="M139.216 13.635c0 2.723-1.332 4.402-3.997 5.036-.91.218-2.611.326-5.107.326h-1.432v9.284h-1.989V8.318h3.993c2.277 0 3.824.099 4.634.297 1.218.297 2.173.896 2.859 1.792.696.896 1.039 1.97 1.039 3.228zm-2.058 0c0-.658-.179-1.262-.542-1.817a2.987 2.987 0 00-1.442-1.188c-.602-.238-1.581-.356-2.939-.356h-3.555v6.758l3.401.04c2.307.03 3.819-.5 4.53-1.585.368-.55.547-1.163.547-1.852zM151.195 21.924c0 1.406-.134 2.525-.402 3.351-.368 1.124-1.04 1.986-2.014 2.585-.816.495-1.835.748-3.058.748-2.407 0-4.033-.936-4.888-2.813-.407-.881-.611-2.208-.611-3.985v-6.016h1.636v5.823c0 1.435.079 2.416.228 2.936a3.266 3.266 0 001.383 1.856c.641.411 1.392.62 2.252.62.835 0 1.571-.194 2.198-.585a3.246 3.246 0 001.377-1.768c.164-.48.244-1.5.244-3.06v-5.822h1.66v6.13h-.005zM154.735 28.28h-1.626V10.973h1.626V28.28zM164.396 24.924c0 1.055-.368 1.936-1.094 2.634-.73.698-1.63 1.045-2.694 1.045-1.547 0-2.78-.59-3.7-1.768l1.015-1.149c.815.916 1.69 1.377 2.61 1.377.607 0 1.144-.193 1.616-.59.472-.39.706-.886.706-1.48 0-.604-.278-1.134-.84-1.59-.274-.227-.821-.549-1.631-.96-.93-.475-1.606-.945-2.034-1.41a3.195 3.195 0 01-.875-2.248c0-.97.328-1.768.985-2.382.656-.614 1.471-.926 2.446-.926 1.153 0 2.312.565 3.48 1.689l-1.034 1.069c-.86-.827-1.7-1.238-2.521-1.238a1.98 1.98 0 00-1.322.49c-.383.327-.577.738-.577 1.223 0 .822.795 1.644 2.392 2.466.979.505 1.695.99 2.138 1.45.621.63.934 1.402.934 2.298zM178.999 22.161h-11.262c.03 1.396.467 2.56 1.313 3.49.89.976 2.023 1.466 3.406 1.466 1.337 0 2.466-.4 3.396-1.213.422-.366.88-.955 1.367-1.772l1.362.713c-.845 1.643-2.018 2.752-3.515 3.327-.731.282-1.556.426-2.476.426-1.885 0-3.441-.62-4.674-1.862-1.233-1.238-1.85-2.797-1.85-4.669a6.66 6.66 0 011.467-4.238c1.238-1.575 2.894-2.367 4.967-2.367 2.134 0 3.844.807 5.117 2.421.905 1.159 1.367 2.58 1.382 4.278zm-1.765-1.396c-.398-1.575-1.258-2.664-2.571-3.273a5.035 5.035 0 00-2.133-.47c-1.233 0-2.287.391-3.172 1.173-.646.57-1.134 1.426-1.467 2.57h9.343z"
          fill="#777"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="53.513"
          y1="19.693"
          x2="54.92"
          y2="19.693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BCBEC0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h179v40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default function Header({ openSidebar }) {
  return (
    <header className="flex shadow-lg z-10">
      <Button
        onClick={openSidebar}
        className="md:hidden h-full px-6 border-r border-b-0 border-t-0 border-l-0 border-cool-gray-200"
        aria-label="Open sidebar"
      >
        <MenuOutlined />
      </Button>
      <div className="flex flex-shrink-0 justify-between items-center px-4 py-2 flex-grow">
        <Link href="/">
          <a className="inline-flex focus:shadow-outline p-2">
            <CarePulseLogo />
          </a>
        </Link>
      </div>
    </header>
  );
}
