import "styles/index.css";
import { useUser } from "hooks/useAuth";
import Navigation from "components/navigation";
import Header from "components/header";
import { ApolloProvider } from "@apollo/client";
import { Alert, Button } from "antd";
import { Router, useRouter } from "next/router";
import "components/team-table.css";
import "components/edit-teams.css";
import "components/questionnaire-navigation.css";
import "components/form-item.css";
import "components/color-select.css";
import "components/question.css";
import "components/technical-evaluator.css";
import "components/ccg-tree.css"
import Head from "next/head";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import React from "react";
import { initGA, logEvent, logPageView } from "lib/ga";
import { isProduction } from "lib/is-production";
import * as Sentry from "@sentry/node";
import Footer from "components/footer";
import { MenuOutlined } from "@ant-design/icons";
import { client } from "lib/apollo";
import { DeadlineContextProvider } from "context/deadline-context";

declare global {
  interface Window {
    GA_INITIALIZED: boolean;
  }
}

function Shell({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const [hideSidebarMenu, setHideSidebarMenu] = React.useState(true);

  const openSidebar = () => {
    setIsSidebarOpen(true);
    setHideSidebarMenu(false);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const hideSidebar = () => {
    setHideSidebarMenu(true);
  };

  const closeOnEscape = (event) => {
    if (event.key === "Escape" && isSidebarOpen && !hideSidebarMenu) {
      closeSidebar();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", closeOnEscape);
    return () => {
      document.removeEventListener("keydown", closeOnEscape);
    };
  }, [isSidebarOpen, hideSidebarMenu, closeSidebar, closeOnEscape]);

  const router = useRouter();
  const { user } = useUser({});
  const showNavigation = router.route !== "/login" && user;
  return (
    <div className="h-screen flex flex-col overflow-hidden">
      <div className="flex flex-col">
        {user && !user?.isActive && (
          <Alert
            showIcon={false}
            message={
              <div>
                We have sent an email to{" "}
                <span className="font-semibold">{user?.email}</span> to verify
                your account. Please check your inbox. If you do not receive an
                email please contact LPH.
              </div>
            }
            banner
          />
        )}
        {showNavigation && <Header openSidebar={openSidebar} />}
      </div>
      <div className="flex flex-grow overflow-hidden bg-gray-100">
        {showNavigation && (
          <Navigation
            isSidebarOpen={isSidebarOpen}
            hideSidebarMenu={hideSidebarMenu}
            closeSidebar={closeSidebar}
            hideSidebar={hideSidebar}
          />
        )}
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <main
            className="flex flex-grow flex-col relative z-0 overflow-y-auto focus:outline-none h-0"
            tabIndex={0}
          >
            <div className="flex flex-col flex-grow pt-2 pb-6 md:py-6">
              <div className="max-w-7xl w-full mx-auto px-4 sm:px-6 md:px-8 h-full">
                {children}
              </div>
            </div>
            {!showNavigation && <Footer />}
          </main>
        </div>
      </div>
    </div>
  );
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: isProduction() ? "production" : "staging",
  });
}

export default function MyApp({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    if (isProduction()) {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      const handleRouteChange = (url) => {
        logPageView(url);
      };
      Router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        Router.events.off("routeChangeComplete", handleRouteChange);
      };
    }
  }, []);
  return (
    <div id="root">
      <Head>
        <title>eProcurement - CarePulse</title>
        <meta
          property="og:title"
          content="eProcurement - CarePulse"
          key="title"
        />
      </Head>
      <ApolloProvider client={client}>
        <DeadlineContextProvider>
          <Shell>
            <Component {...pageProps} />
          </Shell>
        </DeadlineContextProvider>
      </ApolloProvider>
    </div>
  );
}

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  if (isProduction()) {
    logEvent({
      category: label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
      action: name,
      value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
      label: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    });
  }
}
