import * as React from "react";
import { Button, Input, List, Modal, Typography, Upload } from "antd";
import { useState } from "react";
import { UploadDocument } from "./upload-document";
import { Form, Formik } from "formik";
import FormItem from "./form-item";
import {
  ThreadsDocument,
  ThreadsQuery,
  useCreateMessageMutation,
  useReadMessageMutation,
} from "generated/graphql";
import moment from "moment";
import { useUser } from "hooks/useAuth";
import { last } from "lodash";
import { schema } from "./new-message";

export const Message: React.FC<{
  item: ThreadsQuery["threads"]["edges"][number]["node"];
  small?: boolean;
  count?: number;
}> = ({ item, small = false, count }) => {
  const { user } = useUser({});
  const [showInput, setShowInput] = useState(false);
  const [visible, setVisible] = useState(false);
  const [readMessage] = useReadMessageMutation();
  const [mutate, { loading }] = useCreateMessageMutation();
  const lastMessage = last(item?.messages);

  const initialValues = {
    subject: lastMessage?.subject,
    body: "",
    file: [],
  };

  return (
    <>
      <Modal
        getContainer="#root"
        title={"Message"}
        visible={visible}
        width={800}
        onCancel={() => {
          setVisible(false);
        }}
        className="text-black"
        footer={null}
      >
        <h4 className="text-base font-bold mb-6 text-black">
          {lastMessage?.subject}
        </h4>
        {item?.messages.map((message, index) => (
          <div key={index} className="mb-4 text-black">
            <div className="flex justify-between items-center mb-2 text-sm">
              <div className="flex">
                <div className="mr-4">
                  Sent by: {message.sender.provideruserprofile.user.fullName}
                </div>
                <div className="text-gray">
                  {moment(message.created)?.format("H:mm DD MMMM YYYY")}
                </div>
              </div>
              <div
                style={{
                  background: "rgba(0, 0, 0, 0.04)",
                  borderRadius: 12,
                  padding: "2px 12px",
                }}
              >
                {message.audience}
              </div>
            </div>
            <p className="mb-0 text-base">
              {message.body.split("\n").map((x, idx) => (
                <p key={idx}>{x}</p>
              ))}
            </p>
            {message.attachment && (
              <Upload
                showUploadList={{
                  showRemoveIcon: false,
                }}
                fileList={
                  [
                    {
                      uid: message.attachment.id,
                      name: message.attachment.name,
                      url: message.attachment.url,
                      status: "done",
                    },
                  ] as any
                }
              />
            )}
          </div>
        ))}
        {showInput && (
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={(values) => {
              mutate({
                variables: {
                  input: {
                    body: values.body,
                    subject: values.subject,
                    attachment: values.file[0] && {
                      name: values.file[0]?.name,
                      url: values.file[0]?.url,
                    },
                    thread: item.id,
                  },
                },
                refetchQueries: [
                  {
                    query: ThreadsDocument,
                    variables: { first: count },
                  },
                ],
              }).then(() => {
                setVisible(false);
                setShowInput(false);
              });
            }}
          >
            <Form>
              <div
                className="mt-4 pt-4 text-black"
                style={{
                  borderTop: "1px solid rgba(232, 232, 232)",
                }}
              >
                {user.lph && (
                  <FormItem
                    label={<div className="text-sm">Subject</div>}
                    name={"subject"}
                    as={Input}
                  />
                )}
                <FormItem
                  label={<div className="text-sm">Message</div>}
                  name={"body"}
                  as={Input.TextArea}
                  rows={4}
                />
                <UploadDocument />
              </div>
              <div className="flex flex-wrap justify-end items-center">
                <Button type="primary" htmlType="submit" loading={loading}>
                  Reply
                </Button>
              </div>
            </Form>
          </Formik>
        )}
        {!(user.lph && item.messages.every((x) => x.audience === "PUBLIC")) &&
          !showInput && (
            <div className="flex flex-wrap justify-end items-center">
              <Button
                type={"primary"}
                onClick={() => {
                  setShowInput(true);
                }}
              >
                Add reply
              </Button>
            </div>
          )}
      </Modal>
      <List.Item
        key={item.id}
        className={`text-black cursor-pointer ${
          item?.messages?.every((message) => message?.read)
            ? "bg-white"
            : "bg-blue"
        } ${
          lastMessage?.read
            ? "hover:bg-gray-100 focus:bg-gray-100"
            : "hover:bg-blue-2 focus:bg-blue-2"
        }`}
        onClick={() => {
          setVisible(true);
          if (!lastMessage?.read) {
            readMessage({
              variables: { id: item.id },
              refetchQueries: [
                {
                  query: ThreadsDocument,
                  variables: { first: count },
                },
                "UnreadMessages",
              ],
            });
          }
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            setVisible(true);
            if (!lastMessage?.read) {
              readMessage({
                variables: { id: item.id },
                refetchQueries: [
                  {
                    query: ThreadsDocument,
                    variables: { first: count },
                  },
                  "UnreadMessages",
                ],
              });
            }
          }
        }}
        tabIndex={0}
      >
        <h4 className="text-base font-bold mb-6">{lastMessage?.subject}</h4>
        <div className="mb-4">
          {item.messages.map((message, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between mb-2 text-sm items-center">
                <div className={`flex ${small ? "flex-col" : ""}`}>
                  <div className="mr-4">
                    Sent by: {message.sender.provideruserprofile.user?.fullName}
                  </div>
                  <div className="text-gray">
                    {moment(message.created)?.format("H:mm DD MMMM YYYY")}
                  </div>
                </div>
                <div
                  style={{
                    background: "rgba(0, 0, 0, 0.04)",
                    borderRadius: 12,
                    padding: "2px 12px",
                  }}
                >
                  {message.audience}
                </div>
              </div>
              <Typography.Paragraph
                ellipsis={small ? { rows: 3, expandable: false } : null}
                className="mb-0 text-base text-black"
              >
                {!small
                  ? message.body
                      .split("\n")
                      .map((x, idx) => <p key={idx}>{x}</p>)
                  : message.body}
              </Typography.Paragraph>
            </div>
          ))}
        </div>
      </List.Item>
    </>
  );
};
