import { Layout, Space } from "antd";

export default function Footer() {
  return (
    <Layout.Footer className="flex bg-arsenic justify-between items-center z-10 flex-wrap">
      <div className="text-white">
        CarePulse™ is powered by Evalucom Consulting Ltd. Designed and built in
        the United Kingdom.
      </div>
      <div className="flex flex-no-wrap ">
        <Space>
          <div>
            <a
              className="text-white hover:underline focus:underline"
              href="/privacy_policy"
              target="_blank"
            >
              Privacy policy
            </a>
          </div>
          <div>
            <a
              className="text-white hover:underline focus:underline"
              href="/terms_of_use"
              target="_blank"
            >
              Terms of use
            </a>
          </div>
        </Space>
      </div>
    </Layout.Footer>
  );
}
